export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',
    currency: '',
    default_language: 'en',

     // PARAMETROS BALLS MORE
     firebase: {
        apiKey: "AIzaSyBfaHFaxjAQ_7ycRgNaFa053dcw0aErI4A",
        authDomain: "ianos-app-sv-balls-more.firebaseapp.com",
        projectId: "ianos-app-sv-balls-more",
        storageBucket: "ianos-app-sv-balls-more.appspot.com",
        messagingSenderId: "139063506269",
        appId: "1:139063506269:web:62c5ec0d4449a8554e24b4",
        measurementId: "G-Y09LT7MSD5"
    },
    googleMapsKey: 'AIzaSyByzSGg2ZDfD0vB6WWOVLJoM_KM2WvlQ1k',
    urlBase: 'https://us-central1-ianos-app-sv-balls-more.cloudfunctions.net',
    apikey: 'b4dbdc0ebb4-013d1a3969bef-4b8a-0987565d',
    urlBaseWompy: 'https://us-central1-ianos-app-sv-balls-more.cloudfunctions.net/Wompi',

    // TWILIO CREDENCIALES IanOS PRO
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/VerificationCheck`,
        TWILIO_AUTH_TOKEN: 'eae5d7503587447a21af4311385d376c',
        TWILIO_ACCOUNT_SID: 'ACe4c513b1b576a81a7304c06951362f8c',
        TWILIO_SERVICE_TOKEN: 'VA037cd4a1d0556aceea8d4d9524af6c24',
        TWILIO_MESSAGING_SERVICE_SID: 'MGdb0d97a058ccfdc75431f7c1aa3a553d'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    mailchimp: {
        apiKey: '4d1b5faa7e6c99414d58feb265309a36-us22',
        server: 'us22',
        listUniqueId: 'd5e6af4f94'
    }
};

